
/* play with vars for different effets */
:root {
    --color-cursor: 223, 211, 195;
    --cursor-outline-shade: 0.3;
    --cursor-size: 10px;
    --cursor-outline-size: 12px;
  }
  
  html,
  body {
    cursor: none !important;
  }
  
  html *,
  body * {
    cursor: none !important;
  }
  
  #cursor-dot,
  #cursor-dot-outline {
    z-index: 999;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.15s ease-in-out, transform 0.15s ease-in-out;
  }
  
  #cursor-dot {
    width: var(--cursor-size);
    height: var(--cursor-size);
    background-color: rgba(var(--color-cursor), 1);
  }
  
  #cursor-dot-outline {
    width: var(--cursor-outline-size);
    height: var(--cursor-outline-size);
    background-color: rgba(var(--color-cursor), var(--cursor-outline-shade));
  }
  