.main-text-airbnb {
    height: 80vh;
    font-size: 80px;
    font-family: "Cinzel";
    color: #dfd3c3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.subtext-airbnb {
    font-size: 40px;
    font-family: "Cinzel";
    color: #dfd3c36d;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -200px;
    text-align: center;
}

.main-text-section-airbnb {
    border-bottom: 1px solid #dfd3c36d;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Cinzel";
    font-weight: 100;
    color: #dfd3c3;

    p {
        color: #dfd3c36d;
    }
}

.main-img-airbnb {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/img/airbnb.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.images-airbnb {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.first-img-airbnb {
    width: 80%;
    height: 100vh;
    background-image: url("../../assets/img/airbnb-1.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.second-img-airbnb {
    width: 80%;
    height: 100vh;
    background-image: url("../../assets/img/airbnb-2.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.third-img-airbnb {
    width: 80%;
    height: 100vh;
    background-image: url("../../assets/img/airbnb-3.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.fourth-img-airbnb {
    width: 80%;
    height: 100vh;
    background-image: url("../../assets/img/airbnb-4.png");
    background-repeat: no-repeat;
    background-size: contain;
}
@media screen and (max-width: 900px) {
    .main-img-airbnb {
        background-attachment: scroll;
        background-size: cover;
        height: 400px;
    }
    .first-img-airbnb,
    .second-img-airbnb,
    .third-img-airbnb,
    .fourth-img-airbnb {
        height: 60vh;
    }

}
@media only screen and (max-width: 600px) {
    .main-text-airbnb {
        font-size: 40px;
        height: 40vh;
    }

    .main-text-section-airbnb h3 {
        font-size: 10px;
    }

    .main-text-section-airbnb p {
        font-size: 8px;
    }

    .main-img-airbnb {
        background-attachment: scroll;
        background-position:center;
        height: 30vh;
    }

    .subtext-airbnb {
        font-size: 20px;
        top: -100px;
    }

    .first-img-airbnb,
    .second-img-airbnb,
    .third-img-airbnb,
    .fourth-img-airbnb {
        height: 30vh;
    }
}

