.main-text-mekkah {
    height: 80vh;
    font-size: 80px;
    font-family: "Cinzel";
    color: #dfd3c3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.main-text-section-mekkah {
    border-bottom: 1px solid #dfd3c36d;
    ;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Cinzel";
    font-weight: 100;
    color: #dfd3c3;

    p {
        color: #dfd3c36d;
    }
}

.main-img-mekkah {
    width: 100%;
    height: 100vh;
}

@media only screen and (max-width: 600px) {
    .main-text-mekkah {
        font-size: 40px;
        height: 40vh
    }

    .main-text-section-mekkah h3{
        font-size: 10px;
    }
    .main-text-section-mekkah p{
        font-size: 8px;
    }
    .main-img-mekkah{
        background-attachment: scroll;
    }
    .subtext-mekkah{
        font-size: 20px;
    }
}