.main-text-shergome {
    height: 80vh;
    font-size: 80px;
    font-family: "Cinzel";
    color: #dfd3c3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.subtext-shergome {
    font-size: 40px;
    font-family: "Cinzel";
    color: #dfd3c36d;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -200px;
}

.main-text-section-shergome {
    border-bottom: 1px solid #dfd3c36d;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Cinzel";
    font-weight: 100;
    color: #dfd3c3;

    p {
        color: #dfd3c36d;
    }
}

.main-img-shergome {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/img/shergome.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.images-shergome {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
@media screen and (max-width: 900px) {
    .main-img-shergome {
        background-attachment: scroll;
        background-size: cover;
        height: 400px;
    }

}

@media only screen and (max-width: 600px) {
    .main-text-shergome {
        font-size: 40px;
        height: 40vh;
    }

    .main-text-section-shergome h3 {
        font-size: 10px;
    }

    .main-text-section-shergome p {
        font-size: 8px;
    }

    .main-img-shergome {
        background-attachment: scroll;
        background-position:center;
        height: 30vh;
    }

    .subtext-shergome {
        font-size: 20px;
    }
}

