.main-text-like-a-local {
    height: 80vh;
    font-size: 80px;
    font-family: "Cinzel";
    color: #dfd3c3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.subtext-like-a-local {
    font-size: 40px;
    font-family: "Cinzel";
    color: #dfd3c36d;
    font-weight: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -200px;
}

.main-text-section-like-a-local {
    border-bottom: 1px solid #dfd3c36d;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-family: "Cinzel";
    font-weight: 100;
    color: #dfd3c3;

    p {
        color: #dfd3c36d;
    }
}

.main-img-like-a-local {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/img/likealocal.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.images-like-a-local{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.first-img-like-a-local {
    width: 80%;
    height: 100vh;
    background-image: url("../../assets/img/like-a-local-3.png");
    background-repeat: no-repeat;
    background-size: contain;
}

.second-img-like-a-local {
    width: 80%;
    height: 100vh;
    background-image: url("../../assets/img/like-a-local-1.png");
    background-repeat: no-repeat;
    background-size: contain;
}
.third-img-like-a-local {
    width: 80%;
    height: 100vh;
    background-image: url("../../assets/img/like-a-local-2.png");
    background-repeat: no-repeat;
    background-size: contain;
}
@media screen and (max-width: 900px) {
    .main-img-like-a-local{
        background-attachment: scroll;
        background-size: cover;
        height: 400px;
    }
    .first-img-like-a-local,
    .second-img-like-a-local,
    .third-img-like-a-local{
        height: 60vh;
    }
   
  }
@media only screen and (max-width: 600px) {
    .main-text-like-a-local {
        font-size: 40px;
        height: 40vh;
    }

    .main-text-section-like-a-local h3{
        font-size: 10px;
    }
    .main-text-section-like-a-local p{
        font-size: 8px;
    }
    .main-img-like-a-local{
        background-attachment: scroll;
        background-position:center;
        height: 30vh;
    }
    .subtext-like-a-local{
        font-size: 20px;
    }
    .first-img-like-a-local,
    .second-img-like-a-local,
    .third-img-like-a-local{
        height: 30vh;
    }
}

