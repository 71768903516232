.footer {
    color: #dfd3c3;
    position: relative;
    top: 300px;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: "Cinzel";
}

.footer-text {
    margin-top: 30px;
    margin-right: 50px;
}

.social-media {
    display: flex;
}

.social-media i {
    margin: 10px;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid #dfd3c3;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-media i a {
    color: #dfd3c3;
}

.sm-icon {
    font-size: 20px;
}

@media screen and (max-width: 600px) {

    .footer {
        font-size: 10px;
        top: 0;
    }

    .sm-icon {
        font-size: 10px;
    }

    .social-media i {
        padding: 5px;
    }
}