.personal-projects-title {
  color: #dfd3c3c7;
  font-family: "Cinzel";
  font-size: 80px;
  font-weight: 300;
  margin: 0 50px 50px 50px;
  position: relative;
}


@media screen and (max-width: 900px) {
  .personal-projects-title {
    margin-top: 0;
  }
}

@media screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }

  .personal-projects-title {
    font-size: 30px;
    margin-top: -200px;
    text-align: center;
  }

  .banner-arrow {
    display: none;
  }

  .carousel {
    top: 100px;
    width: 30%;
  }
}