.drawer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
  width: 100%;
  height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10%;
  transform: translateY(-100vh);
  animation-name: animate;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background: #080808;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-family: "Cinzel";
}

@keyframes animate {
  0% {
    transform: translateY(-100vh);
  }

  100% {
    transform: translateY(0);
  }
}

.drawer-items {
  list-style-type: none;
  margin-top: 125px;
}

.drawer-items li {
  color: #dfd3c3;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 2s forwards;
  font-size: 3rem;
  width: 0;
  margin: 20px;
}

.drawer-items li:hover {
  color: #dfd3c3c9;
  transform: scale(1.05)
}

.container {
  display: inline-block;
}

@keyframes typing {
  0% {
    width: 0;
  }

  30% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.drawer-item {
  color: #dfd3c3;
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .drawer {
    height: 2000px;
    width: 100vw;
  }

  .drawer-items li {
    font-size: 50px;
  }
}
@media screen and (max-width: 900px) {
  .drawer {
    height: 2000px;
    width: 100vw;
  }

  .drawer-items li {
    font-size: 50px;
  }
}