.nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 2rem;
  cursor: none;
  font-family: "Cinzel";
}

.hamburger-menu {
  padding: 20px;
  box-sizing: border-box;
  background-image: linear-gradient(to right, rgba(235, 122, 228, 0.1), rgba(82, 144, 199, 0.1));
  border-radius: 50px;
}


@media screen and (max-width: 600px) {
  .hamburger-menu {
     padding: 0px;
     background-color: transparent;
     background-image: none;
     position: absolute;
     right:0;
  }
  .nav{
    position: relative;
  }

}