.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experience,
.skills-tools,
.education,
.accomplishments {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: space-around;
    color: #dfd3c3;
    font-family: "Cinzel";
    padding: 50px;
    border-bottom: 1px solid #dfd3c331;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 20px 0;
}

.accomplishments {
    border-bottom: none;
}

.experience-title,
.skills-tools-title,
.education-title,
.accomplishments-title {
    font-size: 35px;
    display: flex;
    justify-content: flex-start;
    width: 350px;
    margin-right: 150px;
}

.skills-tools-list,
.accomplishments-list,
.experience-list {
    font-size: 20px;
}

.skills-tools-list li,
.accomplishments-list li,
.experience-list li,
.education-list li {
    margin: 10px 0;
    list-style-type: "";


    &::before {
        content: "\00BB";
        color: #dfd3c3;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }
}

.education-content,
.accomplishments-content,
.skills-tools-content,
.experience-content {
    width: 100%;
    height: auto;
}

.skills-tools {
    background-image: linear-gradient(to right, rgb(235, 122, 228, 0.2), rgb(82, 145, 199, 0.2));
    padding: 50px;
    border: none
}

.experience-content {
    height: 600px;
    overflow: scroll;
}

.experience-content::-webkit-scrollbar {
    -webkit-appearance: none;
}

.experience-content::-webkit-scrollbar:vertical {
    width: 6px;
}

.experience-content::-webkit-scrollbar:horizontal {
    height: 0px;
}

.experience-content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    // border: 2px solid #dfd3c3;
    background: transparent;
    border: 1px solid;
    border-image: linear-gradient(to right, rgba(235, 122, 228, 0.3), rgba(82, 144, 199, 0.3)) 1;
}

.education-content h4,
.experience-content h4 {
    color: #dfd3c3b2;
}

.accomplishments-list {
    text-align: justify;
}

.accomplishments-list li {
    font-size: 20px;
    margin: 10px 0;
}

.experience-section,
.education-section {
    border-bottom: 1px solid #dfd3c331;
    padding: 50px 0;
}

.experience-section:last-child,
.education-section:last-child {
    border-bottom: none;
}

.skills-tools-grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}


.skills-tools-grid div img {
    width: 50px;
    height: auto;
}

.skills-tools-grid div img:hover {
    transform: scale(1.2);
}

.skills-tools-grid div {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 900px) {
    body {
        overflow-x: hidden !important;
    }

    .experience,
    .technical-skills,
    .tools,
    .education,
    .accomplishments,
    .skills-tools {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0;
        word-wrap: normal;
    }

    .experience-title,
    .skills-tools-title,
    .education-title,
    .accomplishments-title {
        font-size: 20px;
        margin-left: 150px;
    }

    .skills-tools-list li,
    .accomplishments-list li,
    .experience-list li,
    .education-list li {
        font-size: 8px;
        word-wrap: normal;
    }

    .experience-section h1,
    .education-section h1 {
        font-size: 12px;
    }

    .experience-section h4,
    .education-section h4 {
        font-size: 10px;
    }

    .skills-tools {
        width: 100%;
        padding: 20px;
    }

    .skills-tools-grid {
        grid-template-columns: repeat(3, 1fr);
        width: 50%;
        margin-top: 40px;
        width: 100%;
    }

    .skills-tools-grid div img {
        width: 30px;
        height: 30px;
    }

}