$sub-color: #dfd3c3;

@mixin shrinkLabel {
    top: -14px;
    font-size: 12px;
    color: $sub-color;
}

.contact-form {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    color: #dfd3c3;
    padding: 30px;
    font-size: 24px;
    position: relative;
    left: 10%;
}

.group{
    position: relative;
}

.form-input {
    background: none;
    background: transparent;
    color: $sub-color;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    height: 4vh;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $sub-color;
    margin: 40px 0;

    &:focus {
        outline: none;
        background:none;
    }
    &:active {
        outline: none;
        background:none;
    }

    &:focus~.form-input-label {
        @include shrinkLabel();
    }
}

.form-input-label {
    color: $sub-color;
    font-size: 20px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 300ms ease all;

    &.shrink {
        @include shrinkLabel();
    }
}

.custom-button {
    min-width: 150px;
    width: 20%;
    height: 50px;
    letter-spacing: 0.5px;
    line-height: 50px;
    padding: 0 15px ;
    font-size: 15px;
    background: transparent;
    color:  #dfd3c3;
    text-transform: uppercase;
    font-family: 'Open Sans Condensed';
    font-weight: bolder;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    float: right;
    border: 1px solid #dfd3c3;
    margin-top: 50px;

    &:hover {
        background-color: #dfd3c3;
        color: black;
    }
}

