.mekkah-orient-img {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/mekkah-orient.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 0.5;
  position: relative;
}

.logistic-nav-img {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/logistic-nav.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 0.5;
  position: relative;
}

.ar-img {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/ar.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 0.5;
  position: relative;
}

.likealocal-img {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/likealocal.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 0.5;
  position: relative;
}

.shergome-img {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/shergome.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 0.5;
  position: relative;
}

.airbnb-img {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/airbnb.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 0.5;
  position: relative;
}

.amazon-img {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/amazon.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 0.6;
  position: relative;
}

.face-recognition-img {
  width: 100%;
  height: 100vh;
  background-image: url('../../assets/img/face-recognition.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  opacity: 0.6;
  position: relative;
}

.projects-text {
  height: 100vh;
  font-size: 20px;
  font-family: "Cinzel";
  color: #dfd3c3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px;
}

.projects-text ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.projects-text ul li {
  text-align: center;
}

.view-more-btn {
  background: transparent;
  border: 4px solid;
  border-image: linear-gradient(to right, rgba(235, 122, 228, 0.3), rgba(82, 144, 199, 0.3)) 1;
  padding: 12px 16px;
  color: #dfd3c3;
}

.view-more-btn:hover {
  background: #dfd3c3;
  border: none;
  color: #031110;
}

div ul h1 {
  font-size: 100px;
}

div ul h4 {
  font-size: 60px;
  color: #dfd3c375;
  font-weight: 100;
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgb(235, 122, 228, 0.6), rgb(82, 145, 199, 0.6));
}

@media screen and (max-width: 600px) {
  .projects-text ul li h1 {
    font-size: 8vw;
  }

  .view-more-btn {
    width: 100px;
    height: 20px;
    font-size: 8px;
    white-space: nowrap;
    padding: 4px 10px;
  }

  .projects-text {
    height: 300px;
  }

  div ul h4 {
    font-size: 4vw;
  }

  .mekkah-orient-img,
  .logistic-nav-img,
  .airbnb-img,
  .amazon-img,
  .ar-img,
  .likealocal-img,
  .shergome-img,
  .face-recognition-img {
    background-size: cover;
    height: 200px;
    width: 100%;
    background-attachment: scroll;
    background-position-x: center;
  }

}

@media screen and (max-width: 900px) {

  .mekkah-orient-img,
  .logistic-nav-img,
  .airbnb-img,
  .amazon-img,
  .ar-img,
  .likealocal-img,
  .shergome-img,
  .face-recognition-img {
    background-size: cover;
    height: 400px;
    width: 100%;
    background-attachment: scroll;
    background-position-x: center;
  }
}