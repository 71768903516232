.projects-title {
  color: #dfd3c3c7;
  font-family: "Cinzel";
  font-size: 80px;
  font-weight: 300;
  margin: 100px 50px 50px 50px;
  position: relative;
}

.projects-grid {
  display: flex;
  overflow: scroll;
  margin: 50px 50px 300px 50px;
  height: 600px;
  gap: 100px;
  position: relative;
}

.projects-grid div {
  position: relative;
  width: 500px;
  height: auto;
}

.projects-grid div img {
  opacity: 1;
  display: block;
  width: 500px;
  height: 350px;
  transition: 0.5s ease;
  backface-visibility: hidden;
  position: relative;
}

.projects-grid div h3 {
  color: white;
  font-size: 28px;
  font-weight: 100;
  // margin-top: -20px;
}

.projects-grid div h6 {
  color: white;
  font-size: 14px;
  font-weight: 100;
  margin-top: -20px;
}

.first-middle,
.second-middle,
.third-middle,
.fourth-middle,
.fifth-middle {
  transition: 0.5s ease;
  opacity: 0;
  width: 30%;
  position: absolute;
  top: -5%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.first-project:hover .first-middle,
.second-project:hover .second-middle,
.third-project:hover .third-middle,
.fourth-project:hover .fourth-middle,
.fifth-project:hover .fifth-middle {
  opacity: 1;
}

.projects-arrow {
  cursor: pointer;
  height: 140px;
  position: absolute;
  left: 35%;
  top: 50px;
  transform: translateX(-50%) translateY(-50%);
  transition: transform .1s;
  width: 30px;

  $transition-time: .15s;

  &-top,
  &-bottom {
    background-color: #666;
    height: 4px;
    left: -5px;
    position: absolute;
    top: 50%;
    width: 100%;

    &:after {
      background-color: #fff;
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      transition: all $transition-time;
    }
  }

  &-top {
    transform: rotate(45deg);
    transform-origin: bottom right;

    &:after {
      left: 100%;
      right: 0;
      transition-delay: 0s;
    }
  }

  &-bottom {
    transform: rotate(-45deg);
    transform-origin: top right;

    &:after {
      left: 0;
      right: 100%;
      transition-delay: $transition-time;
    }
  }

  &:hover & {

    &-top:after {
      left: 0;
      transition-delay: $transition-time;
    }

    &-bottom:after {
      right: 0;
      transition-delay: 0s;
    }
  }

  &:active {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
  }
}

.projects-grid::-webkit-scrollbar {
  -webkit-appearance: none;
}

.projects-grid::-webkit-scrollbar:horizontal {
  height: 6px;
}

.projects-grid::-webkit-scrollbar:vertical {
  width: 0px;
}

.projects-grid::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid #dfd3c395;
}

.hash-link {
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  #projects {
    margin-top: 0;
  }

  .projects-grid div {
    position: relative;
    width: 500px;
    height: auto;
  }

}

@media screen and (max-width: 600px) {
  .projects-title {
    margin-top: 0;
    font-size: 30px;
    text-align: center;
  }

  .projects-grid {
    display: block;
    overflow-x: hidden;
    margin: auto;
    width: fit-content;
    margin-bottom: 300px;
  }

  .projects-grid div {
    width: fit-content;
  }

  .projects-grid div img {
    width: 200px;
    height: auto;
  }

  .projects-arrow {
    display: none;
  }
  .projects-grid::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .projects-grid::-webkit-scrollbar:horizontal {
    width: 0px;
  }

  .projects-grid::-webkit-scrollbar:vertical {
    width: 6px;
  }

  .projects-grid div h3 {
    font-size: 20px;
  }

  .projects-grid div h6 {
    font-size: 10px;
  }
}