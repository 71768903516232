.about {
  position: relative;
}

.scrolling-text {
  position: relative;
  left: -35%;
  transform: rotate(-90deg);
}

#scroll-container {
  border-radius: 5px;
  height: 120px;
  overflow: hidden;
  white-space: nowrap;
  font-family: "Cinzel";
  font-size: 120px;
}

#scroll-text-first {
  height: 100%;
  text-align: center;
  color: #dfd3c325;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 25s linear infinite;
  -webkit-animation: my-animation 25s linear infinite;
  animation: my-animation 25s linear infinite;
}

#scroll-text-second {
  height: 100%;
  text-align: center;
  color: #dfd3c3;
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: my-animation 30s linear infinite;
  -webkit-animation: my-animation 30s linear infinite;
  animation: my-animation 30s linear infinite;
}

/* for Firefox */
@-moz-keyframes my-animation {
  from {
    -moz-transform: translateX(-50%);
  }

  to {
    -moz-transform: translateX(0%);
  }
}

/* for Chrome */
@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-50%);
  }

  to {
    -webkit-transform: translateX(0%);
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  to {
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.about-me {
  color: #dfd3c354;
  font-weight: 300;
  font-size: 40px;
  font-family: "Cinzel";
  height: 250px;
  display: flex;
  flex-direction: column;
  text-align: left;
  position: absolute;
  top: 0;
  left: 40%;
}

.name {
  font-size: 72px;
  color: #dfd3c3;
}

.more-about-me {
  color: #dfd3c3;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  font-family: "Cinzel";
  padding: 20px 40px 20px;
  outline: 0;
  cursor: pointer;
  position: absolute;
  left: 0;
  bottom: 0;
  background: transparent;
  border: 4px solid;
  border-image: linear-gradient(to right, rgba(235, 122, 228, 0.3), rgba(82, 144, 199, 0.3)) 1;
}

.more-about-me:hover {
  background-image: linear-gradient(to right, rgb(235, 122, 228, 0.3), rgb(82, 145, 199, 0.3));
}

.card {
  margin-left: 50px;
  margin-bottom: 30px;
  width: 700px;
  height: 500px;
  padding: 100px;
  box-shadow: rgb(0, 0, 0) 0px 5px 15px;
}

@media only screen and (max-width: 900px) {
  #scroll-container:first-of-type {
    margin-bottom: 30px;
  }

  .scrolling-text {
    top: -100px;
    left: 0;
    transform: rotate(0deg);
  }

  .about-me {
    width: 65%;
    height: 300px;
    font-size: 24px;
    margin-top: 250px;
    margin-left: -100px;

    h3 {
      font-size: 10px;
    }
  }

  .more-about-me {
    font-size: 8px;
  }

}

@media only screen and (max-width: 600px) {
  body {
    overflow-x: hidden;
  }

  .scrolling-text {
    top: -100px;
    left: 0;
    transform: rotate(0deg);
  }

  #scroll-container {
    font-size: 70px;
  }

  #scroll-container:first-of-type {
    margin-bottom: -30px;
  }

  .name {
    font-size: 24px;
    position: relative;
  }

  .about-me {
    width: 65%;
    font-size: 16px;
    margin-top: 100px;
    margin-left: -50px;
    height: 200px;
  }

  .about-me span:first-of-type {
    margin-top: 30px;
  }


  h3 {
    font-size: 10px;
  }

  .more-about-me {
    font-size: 8px;
  }

}